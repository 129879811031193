import React from 'react';
import {IconButton, Typography} from "@mui/material";
import dayjs from "dayjs";
import {Delete, Repeat, Restaurant} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {actionDeleteRecurrentOrder} from "../../../reduxStore/actions/actionCustomers";

const RecurrentPurchaseCard = ({recurrent}) => {

    const dispatch = useDispatch()

    const parseWeekDays = (data) => {
        const arrayOfDays = []
        data.forEach(day => {
            if (day == "monday") {
                arrayOfDays.push("Mo")
            }
            if (day == "tuesday") {
                arrayOfDays.push("Di")
            }
            if (day == "wednesday") {
                arrayOfDays.push("Mi")
            }
            if (day == "thursday") {
                arrayOfDays.push("Do")
            }
            if (day == "friday") {
                arrayOfDays.push("Fr")
            }
            if (day == "saturday") {
                arrayOfDays.push("Sa")
            }
            if (day == "sunday") {
                arrayOfDays.push("So")
            }
        })
        return (
            <div style={{flexDirection: "row", alignItems: "center", display: "flex"}}>
                <Repeat style={{marginRight: 5}}/>
                <Typography variant="span" fontSize={14} style={{padding: 0}}>
                    {arrayOfDays.join(", ")}
                </Typography>
            </div>
        )
    }

    const deleteItem = (data) => {
        if (confirm("Möchtest du diese geplante Dauerbestellung wirklich löschen?")) {
            dispatch(actionDeleteRecurrentOrder(data));
        }
    }

    return (
        <div style={{
            height: 100,
            backgroundColor: "#fff",
            marginBottom: 10,
            marginHorizontal: 15,
            borderRadius: 5,
            paddingLeft: 15,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            border: "1px solid lightgray"
        }}
        >
            <div style={{flex: 3, display: "flex", flexDirection: "column"}}>
                <img src={`${process.env.REACT_APP_SITE_URL}/assets/${recurrent.fk_order_items.images[0]}`}
                     alt="Produkt recurrent order"
                     style={{height: 50, width: 50, borderRadius: "50%"}}/>
            </div>

            <div style={{flex: 4, display: "flex", flexDirection: "column"}}>
                {/*NAME*/}
                <Typography
                    variant="h6"
                    fontSize={14}
                    style={{padding: 0}}
                >
                    {recurrent.fk_order_items.name}
                </Typography>
                <Typography variant="span" fontSize={14} style={{paddingBottom: 0}}>
                    {`${recurrent.fk_order_items.updated_product_amount} ${recurrent.fk_order_items.measurement}`}
                </Typography>

                {/*PRICE*/}
                <Typography variant="span" fontSize={14} style={{paddingBottom: 0}}>
                    {`€ ${(recurrent.total - recurrent.delivery_fee).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`}
                </Typography>
            </div>

            <div style={{flex: 5, display: "flex", flexDirection: "column"}}>
                {/*FARMER NAME*/}

                <div style={{flexDirection: "row", alignItems: "flex-start", display: "flex"}}>
                    <Restaurant fontSize="small" name="restaurant" style={{marginRight: 5}}/>
                    <Typography variant="span" fontSize={14} style={{padding: 0}}>
                        {recurrent.fk_farmer.store_name}
                    </Typography>
                </div>


                {/*ZEITRAUM*/}
                <Typography variant="span" fontSize={14} style={{marginBottom: 0}}>
                    {`${dayjs(recurrent.date_start).format("DD.MM.YY")} - ${dayjs(recurrent.date_end).format("DD.MM")}`}
                </Typography>

                {/*HAUFIGKEIT*/}
                {parseWeekDays(recurrent.order_repeat)}
            </div>

            <div style={{flex: 2, flexDirection: "column", alignItems: "center"}}>
                <IconButton onClick={() => deleteItem(recurrent)} style={{paddingVertical: 10, paddingHorizontal: 10}}>
                    <Delete color="error"/>
                </IconButton>
            </div>
        </div>
    );
};

export default RecurrentPurchaseCard;

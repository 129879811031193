import React, {useEffect, useState} from 'react';
import store from "../../../../reduxStore/store";
import {useDispatch, useSelector} from "react-redux";
import {getAxiosInstance} from "../../../../utils/interceptor";
import Typography from "@mui/material/Typography";
import {
    Box,
    Checkbox,
    Chip,
    FormControl, FormControlLabel, FormGroup,
    Input,
    InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput, Popover,
    Select,
    TextField
} from "@mui/material";
import MDButton from "../../../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import StoreCategories from "./Components/StoreCategories";
import MultiImageBanner from "../../../product/Components/MultiImageBanner";
import {updateBusinessDetails} from "../../../../reduxStore/actions/actionUserProfile";
import {ArrowDropDown, Info, InfoOutlined, Timer} from "@mui/icons-material";
import {loadingStart, loadingStop} from "../../../../reduxStore/actions/actionUI";
import {Calendar} from "@mui/x-date-pickers/internals/components/icons";
import MDBox from "../../../../components/MDBox";
import colors from "../../../../assets/theme/base/colors";

const ContentBusinessSettings = () => {

    const farmer_id = useSelector(state => state.userProfile.id);

    const loading = useSelector(state => state.UI.loading);
    const [businessData, setBusinessData] = useState({
        company_name: '',
        store_thumbnail: '',
        description: '',
        short_description: '',
        shipping_availability: [],
        delivery_deadline: '',
        delivery_deadline_days_before: 1,
        delivery_fee: '',
        farmer_categories: [],
        fk_global_tax: {},
        store_visible_public: false,
    });
    const [taxes, setTaxes] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);


    const dispatch = useDispatch();

    // GET BUSINESS DATA
    const axiosParams = {
        params: {
            "filter[id][_eq]": farmer_id,
            fields: "id, " +
                "store_name, " +
                "store_description, " +
                "short_description, " +
                "shipping_availability, " +
                "delivery_deadline, " +
                "delivery_deadline_days_before, " +
                "delivery_fee, " +
                "store_thumbnail, " +
                "fk_global_tax.*, " +
                "store_visible_public, " +
                "fk_farmer_categories.*"
        },
    }

    // GET ALL CATEGORIES FROM PRODUCTS AND TAX VALUES
    useEffect(() => {
        getAxiosInstance(store).get('/items/tax')
            .then(response => {
                setTaxes(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    // GET BUSINESS DETAILS
    useEffect(() => {
        dispatch(loadingStart());
        getAxiosInstance(store).get('/items/farmer_profile', axiosParams)
            .then(response => {
                dispatch(loadingStop());
                const data = response.data.data[0];
                setBusinessData(prevState => ({
                    ...prevState,
                    id: data.id,
                    company_name: data.store_name,
                    description: data.store_description,
                    short_description: data.short_description,
                    delivery_fee: data.delivery_fee,
                    delivery_deadline: data.delivery_deadline,
                    delivery_deadline_days_before: data.delivery_deadline_days_before,
                    shipping_availability: data.shipping_availability,
                    farmer_categories: data.fk_farmer_categories.map(categories => categories.farmer_categories_id),
                    store_thumbnail: data.store_thumbnail,
                    store_visible_public: data.store_visible_public,
                    fk_global_tax: {
                        id: data.fk_global_tax.id
                    },
                }))
            })
            .catch(error => {
                dispatch(loadingStop());
                console.log(error.response)
            })
    }, []);

    // HANDLE SHIPPING AVAILABILITY
    const weekdays = [
        {key: "monday", name: "Montag"},
        {key: "tuesday", name: "Dienstag"},
        {key: "wednesday", name: "Mittwoch"},
        {key: "thursday", name: "Donnerstag"},
        {key: "friday", name: "Freitag"},
        {key: "saturday", name: "Samstag"},
        {key: "sunday", name: "Sonntag"},
    ]
    const renderDates = (selected) => (
        <Box sx={{flexDirection: "row", display: "flex", alignItems: "center", gap: 1}}>
            {selected.map((value) => {
                    const day = weekdays.find(day => day.key === value);
                    return (
                        <Chip variant="outlined" key={day?.key} label={day?.name}/>
                    )
                }
            )}
        </Box>
    )
    const handleDateChange = (event) => {
        setBusinessData((prevState) => ({
                ...prevState,
                shipping_availability: event.target.value,
            })
        );
    };

    // CATEGORIES CHANGEs
    const handleCategoriesChanges = (value) => {
        setBusinessData(prevState => ({
                ...prevState,
                farmer_categories: value
            })
        );
    }

    // UPDATE CHANGES
    const submitProfileDetails = () => {

        const submitData = {
            store_name: businessData.company_name,
            delivery_fee: businessData.delivery_fee,
            shipping_availability: businessData.shipping_availability,
            store_description: businessData.description,
            short_description: businessData.short_description,
            delivery_deadline: businessData.delivery_deadline,
            delivery_deadline_days_before: businessData.delivery_deadline_days_before,
            store_thumbnail: businessData.store_thumbnail,
            fk_global_tax: businessData.fk_global_tax,
            store_visible_public: businessData.store_visible_public,
            fk_farmer_categories: businessData.farmer_categories.map(category => (
                {
                    farmer_categories_id: category
                }
            ))
        }
        dispatch(updateBusinessDetails(submitData, farmer_id))
    }

    // INFO POPOVER DISPLAY SHOP PUBLIUCLY
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="container">
            <div className="row">
                <div className="col mb-5">
                    <Typography fontSize={32}>Geschäftliche Details</Typography>
                </div>
            </div>
            <div className="row">
                <div className="col" style={{maxWidth: 400}}>
                    <form>
                        <div className="form-group">
                            {/*COMPANY NAME*/}
                            <FormControl fullWidth sx={{marginBottom: 2}}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="standard-basic"
                                    value={businessData.company_name}
                                    variant="standard"
                                    label="Geschäftsname"
                                    onChange={(e) => setBusinessData(prevState => ({
                                        ...prevState,
                                        company_name: e.target.value
                                    }))}
                                />
                            </FormControl>

                            {/*IS THE STORE PUBLICLY?*/}
                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                <FormControlLabel
                                    aria-describedby={id}
                                    control={
                                        <Checkbox checked={businessData.store_visible_public}
                                                  onClick={() => setBusinessData(prevState => ({
                                                      ...prevState,
                                                      store_visible_public: !businessData.store_visible_public
                                                  }))}
                                                  style={{color: colors.foodhiOrange.main}}
                                        />
                                    }
                                    label={
                                        <p style={{fontSize: 14, fontWeight: 400}}>
                                            Shop öffentlich anzeigen
                                        </p>
                                    }
                                />
                                <Info style={{marginLeft: 5, cursor: "pointer"}} onClick={e => handleClick(e)} fontSize="small"/>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleClose}
                                >
                                    <Typography
                                        variant="p"
                                        fontSize={13}
                                        sx={{backgroundColor: "#fff", maxWidth: 100, shadow: "none", scroll: "none"}}
                                    >
                                        Meinen Shop auf der Foodhi-Plattform anderen Restaurant anzeigen
                                    </Typography>
                                </Popover>
                            </MDBox>



                            {/*SHORT DESCRIPTION*/}
                            <FormControl fullWidth>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="standard-basic"
                                    value={businessData.short_description}
                                    variant="standard"
                                    label="Kurzbeschreibung"
                                    onChange={(e) => setBusinessData(prevState => ({
                                        ...prevState,
                                        short_description: e.target.value
                                    }))}
                                />
                            </FormControl>

                            {/*LIEFERGEBÜHR*/}
                            <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                <InputLabel htmlFor="standard-adornment-amount">Liefergebühr</InputLabel>
                                <Input
                                    id="standard-adornment-amount"
                                    type="number"
                                    sx={{fontSize: 14}}
                                    value={businessData.delivery_fee}
                                    onChange={(e) => setBusinessData(prevState => ({
                                        ...prevState,
                                        delivery_fee: e.target.value
                                    }))}
                                    startAdornment={<InputAdornment position="start"><span
                                        style={{fontSize: 16}}>€</span></InputAdornment>}
                                />
                            </FormControl>

                            {/*/!*STANDARD TAX*!/*/}
                            {/*{(taxes.length > 0 && businessData.fk_global_tax.id) && (*/}
                            {/*    <Box pt={3}>*/}
                            {/*        {!businessData.fk_global_tax &&*/}
                            {/*            <InputLabel htmlFor="standard-adornment-amount">Steuer</InputLabel>}*/}
                            {/*        <FormControl fullWidth variant="standard">*/}
                            {/*            <Select*/}
                            {/*                labelId="tax-select-label"*/}
                            {/*                id="tax-select"*/}
                            {/*                value={businessData.fk_global_tax?.id}*/}
                            {/*                label="Standard-Steuer"*/}
                            {/*                endAdornment={*/}
                            {/*                    <InputAdornment position="end">*/}
                            {/*                        <ArrowDropDown fontSize="medium" color="standard"/>*/}
                            {/*                    </InputAdornment>*/}
                            {/*                }*/}
                            {/*                required*/}
                            {/*                onChange={(e) => setBusinessData(prevState => ({*/}
                            {/*                    ...prevState,*/}
                            {/*                    fk_global_tax: {id: e.target.value}*/}
                            {/*                }))}*/}
                            {/*                disabled={loading && true}*/}
                            {/*                style={{minHeight: "40px"}}*/}
                            {/*            >*/}
                            {/*                {taxes.map(tax => (*/}
                            {/*                    <MenuItem key={tax.id} value={tax.id}>{tax.name}</MenuItem>))}*/}
                            {/*            </Select>*/}
                            {/*        </FormControl>*/}
                            {/*    </Box>*/}
                            {/*)}*/}


                            {/*DELIVERY DEADLINE*/}
                            {/*<h6 style={{marginBottom: 10, marginTop: 20}}>*/}
                            {/*    Bestellfrist*/}
                            {/*</h6>*/}
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 20,
                                marginBottom: 20,
                                marginTop: 20
                            }}>
                                <FormControl fullWidth variant="standard" sx={{flex: 1}}>
                                    <InputLabel htmlFor="standard-adornment-amount">Bestellfrist</InputLabel>
                                    <Input
                                        id="standard-adornment-amount"
                                        type="time"
                                        sx={{fontSize: 14}}
                                        value={businessData.delivery_deadline}
                                        onChange={(e) => setBusinessData(prevState => ({
                                            ...prevState,
                                            delivery_deadline: e.target.value
                                        }))}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Timer color="foodhiOrange"/>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl fullWidth variant="standard" sx={{flex: 1}}>
                                    <InputLabel htmlFor="standard-adornment-amount">Tage vorher</InputLabel>
                                    <Select
                                        label="Tage vorher"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Calendar color="foodhiOrange"/>
                                            </InputAdornment>
                                        }
                                        sx={{fontSize: 14, paddingBottom: 0.5, paddingTop: 0.5}}
                                        endAdornment={<InputAdornment position="end"><ArrowDropDown/></InputAdornment>}
                                        value={businessData.delivery_deadline_days_before}
                                        onChange={(e) => setBusinessData(prevState => ({
                                            ...prevState,
                                            delivery_deadline_days_before: e.target.value
                                        }))}
                                    >
                                        <MenuItem value={0}>Am selben Tag</MenuItem>
                                        <MenuItem value={1}>1 Tag vorher</MenuItem>
                                        <MenuItem value={2}>2 Tage vorher</MenuItem>
                                        <MenuItem value={3}>3 Tage vorher</MenuItem>
                                        <MenuItem value={4}>4 Tage vorher</MenuItem>
                                        <MenuItem value={5}>5 Tage vorher</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                            {/*KATEGORIEN*/}
                            <StoreCategories
                                farmerId={farmer_id}
                                callback={handleCategoriesChanges}
                                farmerCategories={businessData.farmer_categories}
                            />

                            {/*DELIVERY AVAILABILITY*/}
                            <FormControl fullWidth sx={{mt: 4}}>
                                <InputLabel
                                    id="lieferung">{loading ? "Laden..." : "Lieferung"}
                                </InputLabel>
                                <Select
                                    labelId="lieferung"
                                    variant="standard"
                                    id="lieferung"
                                    required
                                    multiple
                                    value={businessData.shipping_availability}
                                    disabled={loading && true}
                                    label={loading ? "Laden..." : "Lieferung"}
                                    sx={{height: 50}}
                                    onChange={handleDateChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                                    renderValue={selected => (renderDates(selected))}
                                >
                                    {weekdays.map((day) => (
                                        <MenuItem key={day.key} value={day.key}>
                                            <Checkbox checked={businessData.shipping_availability.includes(day.key)}/>
                                            {day.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/*BANNERS*/}
                            <FormControl fullWidth sx={{mt: 2}}>
                                <Typography color="gray" fontSize={14} fontFamily="Roboto">Banner</Typography>
                                <MultiImageBanner/>
                            </FormControl>

                            {/*DESCRIPTION*/}
                            <FormControl fullWidth sx={{mt: 2}}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    id="standard-basic"
                                    value={businessData.description}
                                    variant="outlined"
                                    label="Beschreibung"
                                    onChange={(e) => setBusinessData(prevState => ({
                                        ...prevState,
                                        description: e.target.value
                                    }))}
                                />
                            </FormControl>
                        </div>


                        <div className="pt-5 pb-4">
                            <MDButton
                                variant="gradient"
                                color="foodhiOrange"
                                onClick={submitProfileDetails}
                                disabled={loading}
                            >
                                {!loading ? "aktualisieren" : <TailSpin color="#fff" height={20}/>}
                            </MDButton>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContentBusinessSettings;

import React, {useCallback, useEffect, useState} from 'react';
import {Autocomplete, FormControl, MenuItem, Select, TextField} from "@mui/material";
import colors from "../../../assets/theme/base/colors";
import DefaultLineChart from "../../../examples/Charts/LineCharts/DefaultLineChart";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {MONTH_LABELS} from "../../../variables";
import { useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";

const YearlyLineChart = () => {

    const [selectedCustomer, setSelectedCustomer] = useState("Alle");
    const [filteredByCustomer, setFilteredByCustomer] = useState({});

    const farmerId = useSelector(state => state.userProfile?.id);


    const [calculatedYearChart, setCalculatedYearChart] = useState({});


    const getLineChartData = () => {
        const currentYear = new Date().getFullYear();

        getAxiosInstance(store).get(`/items/orders`, {
            params: {
                "filter[year(date_created)][_eq]": currentYear,
                "filter[farmer][_eq]": farmerId,
                // "filter[fk_order_status][name]": "completed",
                "fields": "date_created, total, restaurant.*, fk_shipping_address.company",
                "limit": 1000
            }
        })
            .then(response => {
                const {data} = response.data;
                calculateDataForChart(data)
            })
            .catch(error => console.log(error, "ERROR GETTING ORDERS STATISTIC"))
    }

    useEffect(() => {
        getLineChartData();
    }, [])

    const calculateDataForChart = (data) => {
        let allOrders = []
        // INFORMATION PER DATA
        data.forEach(chartData => {
            // EXTRACT THE MONTH NUMBER
            const orderMonth = new Date(chartData.date_created);
            const orderMonthNumber = (orderMonth.getMonth());
            // EXTRACT THE CUSTOMER NAME, IF NO RESTAURANT LINKED TO ORDER, GET COMPANY NAME FROM SHIPPINT ADDRESS
            const customer = chartData.restaurant ?chartData.restaurant.restaurant_name : chartData.fk_shipping_address?.company;
            const colour = chartData.restaurant?.colour ? chartData.restaurant?.colour : "#3D3D3D";
            if (customer) {
                allOrders.push({month: orderMonthNumber, customer, sales: chartData.total, colour})
            }
        })

        const reducedOrders = {}
        const resultReducedOrders = allOrders.reduce(function (r, e) {
            const key = e.customer + '|' + e.month;
            if (!reducedOrders[key]) {
                reducedOrders[key] = e;
                r.push(reducedOrders[key]);
            } else {
                reducedOrders[key].sales += e.sales;
            }
            return r;
        }, []);

        const salesDataObj = {labels: [], datasets: []};
        // ---> LABELS <---
        MONTH_LABELS.forEach(month => {
            salesDataObj.labels.push(month.label)
        })

        // ---> DATASETS <----
        resultReducedOrders.forEach(item => {
            // CREATE AN ARRAY WITH THE MONTHS OF THE YEAR
            let yearlyValuesArray = [];
            MONTH_LABELS.forEach(monthOfTheYear => {
                if (item.month === monthOfTheYear.value - 1) {
                    yearlyValuesArray.push(item.sales)
                } else yearlyValuesArray.push(0)
            })

            //CREATE PARSED OBJECT WITH THE 3 NEEDED KEYS FOR CREATING THE TABLE
            salesDataObj.datasets.push({
                label: item.customer,
                color: item.colour,
                data: yearlyValuesArray
            })
        })

        // GET THE SEPARATE ARRAYS FOR THE DATASET AND SUM THEM SO WE GET JUST ONE ARRAY FOR THE WHOLE YEAR
        function sumArrays(arrays) {

            const result = [];
            const length = arrays[0].length; // Assuming all arrays have the same length

            for (let i = 0; i < length; i++) {
                let sum = 0;
                for (let j = 0; j < arrays.length; j++) {
                    sum += arrays[j][i];
                }
                result.push(sum);
            }
            return result
        }

        // CREATE NEW FINAL ARRAY AND SET IT IN A STATE
        let arrays = [];
        salesDataObj.datasets.forEach(data => {
            arrays.push(data.data)
        })

        let reducedArrays = Object.values(salesDataObj.datasets.reduce((acc, {color, data, label}) => {
            if (acc[label]) acc[label].data.forEach((x, i, a) => a[i] += data[i]);
            else acc[label] = {color, data, label};
            return acc;
        }, {}));

        const arrayByLabel = [];
        for (const element of reducedArrays) {
            const {label, color, data} = element;
            const index = arrayByLabel.findIndex(obj => obj.label === label);

            if (index !== -1) {
                arrayByLabel[index].color = color;
                arrayByLabel[index].data = data;
            } else {
                arrayByLabel.push({label, color, data});
            }
        }

        setCalculatedYearChart({
            labels: salesDataObj.labels,
            datasets: arrayByLabel
        })
    }

    const filteredDataPerCustomer = useCallback(() => {
        if (calculatedYearChart) {
            if (selectedCustomer !== "Alle") {
                setFilteredByCustomer({
                    labels: calculatedYearChart.labels,
                    datasets: calculatedYearChart.datasets.filter(item => item.label === selectedCustomer)
                });
            } else setFilteredByCustomer({
                labels: calculatedYearChart.labels,
                datasets: calculatedYearChart.datasets
            });
        }
    }, [selectedCustomer, calculatedYearChart]);

    const setSelectedCustomerCallback = (data) => {
      setSelectedCustomer(data)
    }

    useEffect(() => {
        filteredDataPerCustomer()
    }, [selectedCustomer, calculatedYearChart])

    if (calculatedYearChart.datasets) {
        return (
            <DefaultLineChart
                icon={{color: colors.foodhiLightGreen.focus, component: "leaderboard"}}
                title="Umsatz pro Kunden"
                description="Jahr 2023"
                chart={filteredByCustomer}
                selectedCustomer={selectedCustomer}
                setSelectedCustomerCallback={setSelectedCustomerCallback}
                calculatedYearChart={calculatedYearChart}
                height={400}
            />
        );
    } else return <Spinner/>

};

export default YearlyLineChart;
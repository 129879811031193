import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox, Chip,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import colors from "../../../assets/theme/base/colors";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import {loadingStart, loadingStop} from "../../../reduxStore/actions/actionUI";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {toast} from "react-toastify";
import RecurrentPurchaseCard from "./RecurrentPurchaseCard";
import {
    actionFetchPreferentialDeliveryDays,
    actionFetchRecurrentOrders
} from "../../../reduxStore/actions/actionCustomers";
import boxShadows from "../../../assets/theme/base/boxShadows";

const CustomerSettingsModal = (props) => {
    // const [lexOfficeCustomerNumber, setLexOfficeCustomerNumber] = useState('');

    const isLoading = useSelector(state => state.UI.loading);
    const farmerId = useSelector(state => state.userProfile.id);
    const recurrentOrders = useSelector(state => state.customers.recurrentOrders);
    const [preferentialDays, setPreferentialDays] = useState({
        days: []
    });

    const customerId = props.settingsModalVisible.restaurant_id;

    const dispatch = useDispatch();

    // const fetchLexOfficeCustomerNumber = () => {
    //     getAxiosInstance(store).get(`items/lexoffice_customer_number`, {
    //         params: {
    //             "filter[fk_restaurant][_eq]": props.settingsModalVisible.restaurant_id,
    //             "filter[fk_farmer][_eq]": farmerId,
    //             fields: "id, " +
    //                 "fk_restaurant," +
    //                 "fk_farmer," +
    //                 "customer_number"
    //         }
    //     })
    //         .then(response => {
    //             if (response.data.data[0]) {
    //                 setLexOfficeCustomerNumber(response.data.data[0])
    //             } else {
    //                 setLexOfficeCustomerNumber({
    //                     customer_number: '',
    //                     restaurant_id: props.settingsModalVisible.restaurant_id,
    //                     farmer_id: farmerId
    //                 })
    //             }
    //             dispatch(loadingStop())
    //         })
    //         .catch(error => {
    //             console.log(error.response)
    //             toast.error("Es ist ein Fehler aufgetreten")
    //             dispatch(loadingStop())
    //         })
    // }

    const fetchPreferentialDays = () => {
        dispatch(loadingStart())
        getAxiosInstance(store).get(`items/preferential_delivery_days`, {
            fields: "days, fk_farmer.id, fk_restaurant.id",
            params: {filter: {fk_restaurant: {_eq: customerId}}}
        })
            .then(response => {
                setPreferentialDays({
                    days: response.data.data[0] ? response.data.data[0].days : [],
                    id: response.data.data[0] && response.data.data[0].id
                })
                dispatch(loadingStop())
            })
            .catch(error => {
                console.log(error)
                toast.error("Es ist ein Fehler aufgetreten.")
                dispatch(loadingStop())
            })
    }

    useEffect(() => {
        dispatch(loadingStart())
        // setLexOfficeCustomerNumber('')
        if (props.settingsModalVisible.visible) {
            // fetchLexOfficeCustomerNumber();
            fetchPreferentialDays()
            dispatch(actionFetchRecurrentOrders(customerId, farmerId));
        }

    }, [props.settingsModalVisible]);

    useEffect(() => {
        dispatch(loadingStop())
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        if(preferentialDays.id) {
            // IF PREFERENTIAL DAYS TABLE ALREADY EXISTS
            getAxiosInstance(store).patch(`items/preferential_delivery_days/${preferentialDays.id}`, { "days": preferentialDays.days} )
                .then(response => {
                    console.log(response)
                    props.closeModal()
                })
                .catch(error => {
                    console.log(error.response)
                    toast.error("Die Bevorzugte Liefertermine konnten nicht bearbeiten werden.")
                })
        } else {
            // IF IT DOESNT EXIST, CREATE IT
            getAxiosInstance(store).post(`items/preferential_delivery_days`, {
                "days": preferentialDays.days,
                fk_restaurant: props.settingsModalVisible.restaurant_id,
                fk_farmer: farmerId
            })
                .then(response => {
                    console.log(response)
                    props.closeModal()
                })
                .catch(error => {
                    console.log(error.response)
                    toast.error("Die Bevorzugte Liefertermine konnten nicht bearbeiten werden.")
                })
        }

        // const data = {
        //     customer_number: lexOfficeCustomerNumber.customer_number,
        //     fk_restaurant: props.settingsModalVisible.restaurant_id,
        //     fk_farmer: farmerId
        // }
        // if (lexOfficeCustomerNumber.id) {
        //     getAxiosInstance(store).patch(`items/lexoffice_customer_number/${lexOfficeCustomerNumber.id}`, data)
        //         .then(response => {
        //             console.log(response)
        //             props.closeModal()
        //             toast.success("Aktualisiert")
        //         })
        //         .catch(error => {
        //             console.log(error.response)
        //             toast.error("Ein Fehler ist aufgetreten")
        //         })
        // } else {
        //     getAxiosInstance(store).post(`items/lexoffice_customer_number`, data)
        //         .then(response => {
        //             console.log(response.data.data)
        //             toast.success("Aktualisiert")
        //             props.closeModal()
        //         })
        //         .catch(error => {
        //             console.log(error.response)
        //             toast.error("Ein Fehler ist aufgetreten")
        //
        //         })
        // }
    }

    const handleChange = (event) => {
        const {target: {value}} = event;
        setPreferentialDays(prevState => ({
            ...prevState,
            days: typeof value === 'string' ? value.split(',') : value
        }));
    };

    function parseWeekDays(item) {
        switch (item) {
            case "monday":
                return "Montag"
                break;
            case "tuesday":
                return "Dienstag"
                break;
            case "wednesday":
                return "Mittwoch"
                break;
            case "thursday":
                return "Donnerstag"
                break;
            case "friday":
                return "Freitag"
                break;
            case "saturday":
                return "Samstag"
                break;
            case "sunday":
                return "Sonntag"
                break;
        }
    }

    const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

    return (
        <Modal
            open={props.settingsModalVisible.visible}
            onClose={props.closeModal}
            aria-labelledby="Kunden-Optionen"
            aria-describedby="Kunden-Optionen"
            style={{overflowY: "auto"}}
        >
            <MDBox pt="5rem" pb={3} width={window.innerWidth * 0.75} margin="auto" sx={{maxWidth: 400}}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={2}
                                px={2}
                                variant="gradient"
                                bgColor="foodhiLightGreen"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography variant="h6" color="white">
                                    Mehr Optionen
                                </MDTypography>
                                <Button
                                    color={colors.foodhiOrange.main}
                                    variant="gradient"
                                    onClick={props.closeModal}
                                >
                                    <Icon sx={{fontWeight: "bold", color: "#fff"}} fontSize="16">cancel</Icon>
                                </Button>
                            </MDBox>
                            <form onSubmit={submitForm}>

                                {/*LEXOFFICE CUSTOMER NUMBER*/}
                                {/*<MDBox pt={3} px={3}>*/}
                                {/*    <h6>Lexoffice Kundennummer</h6>*/}
                                {/*    <MDBox pt={2} pb={1}>*/}
                                {/*        <MDInput*/}
                                {/*            type="text"*/}
                                {/*            disabled={isLoading}*/}
                                {/*            label="Lexoffice Kundennummer"*/}
                                {/*            fullWidth*/}
                                {/*            onChange={(e) => setLexOfficeCustomerNumber(prevState => ({*/}
                                {/*                ...prevState,*/}
                                {/*                customer_number: e.target.value*/}
                                {/*            }))}*/}
                                {/*            value={isLoading ? '' : lexOfficeCustomerNumber.customer_number}*/}
                                {/*        />*/}
                                {/*    </MDBox>*/}

                                {/*</MDBox>*/}

                                {/*OVERRIDE DELIVERY DATES*/}
                                <MDBox pt={3} px={3}>
                                    <h6>Persönliche Liefertage</h6>
                                    <MDBox pt={2} pb={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                                            <Select
                                                multiple
                                                label="Tag"
                                                style={{height: 45}}
                                                value={preferentialDays.days}
                                                onChange={handleChange}
                                                renderValue={(selected) => (
                                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                        {selected.map(item => <Chip key={parseWeekDays(item)}
                                                                                    label={parseWeekDays(item)}/>)}
                                                    </Box>
                                                )}>
                                                {
                                                    weekDays.map(item => {
                                                        return (
                                                            <MenuItem value={item}>
                                                                <Checkbox
                                                                    checked={preferentialDays.days.indexOf(item) > -1}/>
                                                                {parseWeekDays(item)}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </MDBox>
                                </MDBox>


                                {/*RECURRENT ORDERS LIST*/}
                                <MDBox pt={3} px={3}>
                                    <h6>Geplante Bestellungen</h6>
                                    <MDBox pt={2} pb={3}>
                                        {
                                            recurrentOrders?.length
                                                ? recurrentOrders.map(recurrent => <RecurrentPurchaseCard
                                                    recurrent={recurrent}/>)
                                                : <Typography
                                                    fontSize={14}
                                                    border={0}
                                                    borderColor="lightgray"
                                                    borderRadius={1.5}
                                                    px={0}
                                                    py={0}
                                                >
                                                    Noch keine geplante Bestellung
                                                </Typography>
                                        }
                                    </MDBox>
                                </MDBox>

                                <MDBox pt={2} pb={3} px={3}>
                                    <MDButton type="submit" variant="gradient" color="info"
                                              disabled={isLoading && true}>
                                        {!isLoading
                                            ? "Aktualisieren"
                                            : <TailSpin height={20} color="lightgray"/>
                                        }
                                    </MDButton>
                                </MDBox>
                            </form>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </Modal>
    );
};

export default CustomerSettingsModal;

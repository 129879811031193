import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import sendEmail from "../../../../../emails/emailApi";
import {fetchOrders} from "../../../../../reduxStore/actions/actionOrders";
import {toast} from "react-toastify";
import moment from "moment";
import {sendPushNotification} from "../../../../../utils/sendPushNotification";
import dayjs from "dayjs";

export function createDeliveryNote(orderData, farmer, dispatch, externalVendorCustomerData) {
    const createLexofficeDeliveryNote = (deliveryData) => {
        // CHECK IF THERE IS ALREADY A DELIVERY NOTE
        getAxiosInstance(store).get(`${process.env.PUBLIC_URL}/items/orders/${orderData.id}`, {params: {fields: "delivery_slip, restaurant.*"}})
            .then(response => {
                // IF RESTAURANT LINKED, GET PUSH NOTIFICATION KEY FROM RESTAURANT USER
                const pushNotificationKey = response.data.data.restaurant?.push_notification_key;

                // IF THERE IS NOT A DELIVERY NOTE YET
                if (!response.data.data.delivery_slip) {
                    console.log("Creating delivery note in Lexoffice")
                    getAxiosInstance(store).post(`${process.env.PUBLIC_URL}/api/create-delivery-note?fields=.*.*`, deliveryData)
                        .then(response => {
                            // IR RESTAURANT LINKED TO ORDER
                            const lieferschein = response.data.data.delivery_slip;

                            if (orderData.restaurant) {
                                // SEND EMAIL FOR SHIPPED ORDER
                                sendEmail({
                                    templateId: "RESTAURANT_Order_Shipped",
                                    data: {...orderData, farmer, lieferschein},
                                    recipient: orderData.restaurant.contact_email,
                                });
                                if (pushNotificationKey) {
                                    // SEND PUSH NOTIFICATION TO RESTAURANT
                                    sendPushNotification(
                                        `${pushNotificationKey}`,
                                        "default",
                                        "Lieferung deiner Bestellung",
                                        `Die Bestellung #${orderData.id} wird bald geliefert`
                                    )
                                }
                            }

                            toast.success("Lieferschein wurde erfolgreich erstellt.")
                            dispatch(fetchOrders(false, farmer.id))

                        })
                        .catch(() => {
                            console.log("Lexoffice error creating Lieferschein");

                            // IR RESTAURANT LINKED TO ORDER
                            if (orderData.restaurant) {
                                sendEmail({
                                    templateId: "RESTAURANT_Order_Shipped",
                                    data: {...orderData, farmer},
                                    recipient: orderData.restaurant.contact_email,
                                });
                            }

                            // EMAIL TO FARMER WHEN LIEFERSCHEIN IS NOT CREATED
                            sendEmail({
                                templateId: "FARMER_Lieferschein_Not_Created",
                                data: {...orderData, farmer},
                                recipient: farmer.contact_email,
                            });
                            // SEND PUSH NOTIFICATION TO RESTAURANT
                            if (pushNotificationKey) {
                                // SEND PUSH NOTIFICATION TO RESTAURANT
                                sendPushNotification(
                                    `${pushNotificationKey}`,
                                    "default",
                                    "Lieferung deiner Bestellung",
                                    `Die Bestellung #${orderData.id} wird bald geliefert`
                                )
                            }
                            dispatch(fetchOrders(false, farmer.id))
                            toast.info("Lieferschein konnte nicht erstellt werden. Bitte stelle sicher, dass du einen manuell erstellst.")
                        });
                } else {
                    toast.info("Lieferschein existiert bereits. Stelle sicher, dass du ihn in Lexoffice aktualisierst.")
                    const lieferschein = response.data.data.delivery_slip;
                    sendEmail({
                        templateId: "RESTAURANT_Order_Shipped",
                        data: {...orderData, farmer, lieferschein},
                        recipient: orderData.restaurant.contact_email,
                    });
                    if (pushNotificationKey) {
                        // SEND PUSH NOTIFICATION TO RESTAURANT
                        sendPushNotification(
                            `${pushNotificationKey}`,
                            "default",
                            "Lieferung deiner Bestellung",
                            `Die Bestellung #${orderData.id} wird bald geliefert`
                        )
                    }
                    dispatch(fetchOrders(false, farmer.id))
                }
            })
            .catch(error => {
                toast.error("Bei der Erstellung des Lieferscheins ist ein Fehler aufgetreten.")
                // EMAIL TO FARMER WHEN LIEFERSCHEIN IS NOT CREATED
                sendEmail({
                    templateId: "FARMER_Lieferschein_Not_Created",
                    data: {...orderData, farmer},
                    recipient: farmer.contact_email,
                });
                console.log(error)
            })
    }
    const createSevdeskDeliveryNote = (deliveryData) => {
        // CHECK IF THERE IS ALREADY A DELIVERY NOTE
        getAxiosInstance(store).get(`${process.env.PUBLIC_URL}/items/orders/${orderData.id}`, {params: {fields: "delivery_slip, restaurant.*"}})
            .then(response => {
                // IF RESTAURANT LINKED TO ORDER, GET PUSH NOT KEY
                const pushNotificationKey = response.data.data.restaurant?.push_notification_key;

                // IF THERE IS NOT A DELIVERY NOTE PREVIOUSLY CREATED fOR THIS ORDER
                if (!response.data.data.delivery_slip) {
                    console.log("Creating delivery note in Sevdesk")
                    getAxiosInstance(store).post(`${process.env.PUBLIC_URL}/api/create-delivery-note?fields=.*.*`, deliveryData)
                        .then(response => {
                            const lieferschein = response.data.data.delivery_slip;

                            // SEND EMAIL FOR SHIPPED ORDER IF RESTAURANT IS LINKED TO ORDER
                            if (orderData.restaurant) {
                                sendEmail({
                                    templateId: "RESTAURANT_Order_Shipped",
                                    data: {...orderData, farmer, lieferschein},
                                    recipient: orderData.restaurant.contact_email,
                                });
                                if (pushNotificationKey) {
                                    // SEND PUSH NOTIFICATION TO RESTAURANT
                                    sendPushNotification(
                                        `${pushNotificationKey}`,
                                        "default",
                                        "Lieferung deiner Bestellung",
                                        `Die Bestellung #${orderData.id} wird bald geliefert`
                                    )
                                }
                            }

                            toast.success("Lieferschein wurde erfolgreich erstellt.")
                            dispatch(fetchOrders(false, farmer.id))
                        })
                        .catch(error => console.log(error, "SEVDESK ERROR"));
                } else {
                    toast.info("Lieferschein existiert bereits.")
                    const lieferschein = response.data.data.delivery_slip;

                    // SEND EMAIL FOR SHIPPED ORDER IF RESTAURANT IS LINKED TO ORDER
                    if (orderData.restaurant) {
                        sendEmail({
                            templateId: "RESTAURANT_Order_Shipped",
                            data: {...orderData, farmer, lieferschein},
                            recipient: orderData.restaurant.contact_email,
                        });
                    }

                    dispatch(fetchOrders(false, farmer.id))
                }
            })
            .catch(error => {
                console.log("Lexoffice error creating Lieferschein", error);

                // IF RESTAURANT LINKED TO ORDER
                if (orderData.restaurant) {
                    sendEmail({
                        templateId: "RESTAURANT_Order_Shipped",
                        data: {...orderData, farmer},
                        recipient: orderData.restaurant.contact_email,
                    });
                    // SEND PUSH NOTIFICATION TO RESTAURANT
                    getAxiosInstance(store).get(`${process.env.PUBLIC_URL}/items/orders/${orderData.id}`, {params: {fields: "restaurant.*"}})
                        .then(response => {
                            if (response.data.data.restaurant.pushNotificationKey) {
                                // SEND PUSH NOTIFICATION TO RESTAURANT
                                sendPushNotification(
                                    `${response.data.data.restaurant.pushNotificationKey}`,
                                    "default",
                                    "Lieferung deiner Bestellung",
                                    `Die Bestellung #${orderData.id} wird bald geliefert`
                                )
                            }
                        })

                }
                sendEmail({
                    templateId: "FARMER_Lieferschein_Not_Created",
                    data: {...orderData, farmer},
                    recipient: farmer.contact_email,
                });

                dispatch(fetchOrders(false, farmer.id))
                toast.info("Lieferschein konnte nicht erstellt werden. Bitte stelle sicher, dass du einen manuell erstellst.")
            })


    }


    // FIRST EXTRACT API KEY AND DETERMINE PROVIDER
    getAxiosInstance(store).get(`/items/farmer_profile/${farmer.id}`, {
        params: {"fields": "fk_api_connections.*"}
    })
        .then(response => {
            const apiKey = response.data.data.fk_api_connections[0].key;
            const provider = response.data.data.fk_api_connections[0].provider;

            const sevdeskUser = provider === "sevdesk" && response.data.data.fk_api_connections[0].sevdesk_contact_person.id;

            const shippingDateToObject = new Date(orderData.delivery_date)
            const shippingDate = shippingDateToObject.toISOString();

            const {company, street_1, city, postcode} = orderData.fk_shipping_address;
            const now = moment().toISOString().split("T")[0];

            const voucherDate = `${now}T00:00:00.000+01:00`

            const lineItems = []
            orderData.fk_order_items.forEach(item => {
                if (provider === "lexoffice") {
                    lineItems.push(
                        {
                            type: "custom",
                            name: `${item.name} (${item.updated_product_amount} ${item.measurement})`,
                            description: item.note,
                            // name: `${item.name})`,
                            quantity: item.quantity,
                            unitName: `Stück`,
                            // unitPrice: item.price.toLocaleString("de-DE", {maximumFractionDigits: 2})
                            unitPrice: {
                                currency: "EUR",
                                netAmount: item.updated_subtotal.toFixed(2),
                                taxRatePercentage: item.tax_rate ? item.tax_rate : farmer.fk_global_tax ? farmer.fk_global_tax?.tax_rate : 0
                            }
                        }
                    )
                }
                if (provider === "sevdesk") {
                    lineItems.push(
                        {
                            "objectName": "OrderPos",
                            "mapAll": "true",
                            "quantity": item.quantity,
                            "price": item.updated_subtotal,
                            "name": `${item.name} (${item.updated_product_amount} ${item.measurement})`,
                            "unity": {
                                "id": 1,
                                "objectName": "Unity"
                            },
                            "discount": 0,
                            "optional": true,
                            "taxRate": item.tax_rate ? item.tax_rate : farmer.fk_global_tax ? farmer.fk_global_tax?.tax_rate : 0
                        }
                    )
                }

            })
            orderData.packaging.forEach(item => {
                if (provider === "lexoffice") {
                    lineItems.push(
                        {
                            type: "custom",
                            name: item.name,
                            quantity: item.amount,
                            unitName: `Stück`,
                            // unitPrice: item.price.toLocaleString("de-DE", {maximumFractionDigits: 2})
                            unitPrice: {
                                currency: "EUR",
                                netAmount: 0,
                                taxRatePercentage: 0
                            }
                        }
                    )
                }
                if (provider === "sevdesk") {
                    lineItems.push(
                        {
                            "objectName": "OrderPos",
                            "mapAll": "true",
                            "quantity": item.amount,
                            "price": 0,
                            "name": item.name,
                            "unity": {
                                "id": 1,
                                "objectName": "Unity"
                            },
                            "discount": 0,
                            "optional": true,
                            "taxRate": 0
                        }
                    )
                }

            })

            const deliveryData = {
                apiKey,
                farmerId: farmer.id,
                orderData,
                provider,
                ...provider === "lexoffice" && {
                    deliveryNoteData: {
                        "archived": false,
                        voucherDate,
                        "address": {
                            ...externalVendorCustomerData && Object.keys(externalVendorCustomerData).length > 0 && {"contactId": externalVendorCustomerData.id},
                            ...(!externalVendorCustomerData || Object.keys(externalVendorCustomerData).length === 0)
                                ? {"name": company}
                                : {
                                    "name": externalVendorCustomerData.company
                                        ? externalVendorCustomerData.company.name
                                        : `${externalVendorCustomerData.person.firstName} ${externalVendorCustomerData.person.lastName}`
                                },
                            "street": street_1,
                            city,
                            "zip": postcode,
                            "countryCode": "DE",
                        },
                        lineItems,
                        "taxConditions": {
                            "taxType": "net"
                        },
                        "title": `Lieferschein`,
                        "introduction": `Lieferschein zur Bestellung ${orderData.id} | ${company}`,
                        "deliveryTerms": `Liefergebühr: € ${(orderData.delivery_fee).toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} (exkl. MwSt.)`,
                        "shippingConditions": {
                            shippingDate,
                            "shippingType": "delivery"
                        }
                    }
                },
                ...provider === "sevdesk" && {
                    deliveryNoteData: {
                        "order": {
                            "id": null,
                            "objectName": "Order",
                            "orderDate": dayjs().format("DD.MM.YYYY"),
                            "mapAll": true,
                            "orderNumber": `${orderData.id}`,
                            "status": 200,
                            "header": `Lieferschein zur Bestellung ${orderData.id} | ${company}`,
                            "contact": {
                                "id": externalVendorCustomerData.id,
                                "objectName": "Contact"
                            },
                            "contactPerson": {
                                "id": sevdeskUser,
                                "objectName": "SevUser"
                            },
                            "addressCountry": {
                                "id": 1,
                                "objectName": "StaticCountry"
                            },
                            "deliveryTerms": `Liefergebühr: € ${(orderData.delivery_fee).toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })} (exkl. MwSt.)`,
                            "version": 0,
                            "taxRate": farmer.fk_global_tax ? farmer.fk_global_tax.tax_rate : 7,
                            "taxText": `Umsatzsteuer ${farmer.fk_global_tax ? farmer.fk_global_tax.tax_rate : 7}%`,
                            "taxType": "default",
                            "orderType": "LI",
                            "sendDate": null,
                            "address": `${company} | ${street_1} | ${postcode} ${city}`,
                            "currency": "EUR",
                            "showNet": 1,
                            "sendType": "VPDF",
                            "origin": null
                        },
                        "orderPosSave": lineItems,
                        "orderPosDelete": null
                    },

                }
            }

            console.log(deliveryData.deliveryNoteData, "DELIVERY DATA SENT")

            if (provider === 'lexoffice') {
                createLexofficeDeliveryNote(deliveryData)
            }
            if (provider === 'sevdesk') {
                createSevdeskDeliveryNote(deliveryData)
            }
        })
        .catch(error => console.log(error))

}